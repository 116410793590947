import React from "react";
import notIcon from "../../assets/icons/not.svg";
import { THEME } from "../../config";
import ColoredSvg from "../icons/ColoredSvg";

export default function Price({ isAvailable, price, categoryIcon }) {
    return (
        <div className={`flex text-center verde`} style={{ backgroundColor: isAvailable ? THEME.primaryColor() : THEME.errorColor }}>
            <ColoredSvg url={isAvailable ? categoryIcon : notIcon} style={{ padding: 5}} stroke={THEME.whiteColor} />
            <p>{isAvailable ? price : "N.D."}</p>
        </div>
    );
}
