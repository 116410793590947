import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { THEME } from "../../config"
import ingredienti from "../../assets/icons/ingredienti.svg"
import back from "../../assets/icons/back.svg"
import { useNavigate } from "react-router-dom"

const SkeletonDettaglioProdotto = () => {
  const navigate = useNavigate()

  return (
    <SkeletonTheme baseColor={"rgb(212,212,212)"} highlightColor="#EAEAEA">
      <main>
        <div className={`flex topbar`}>
          <div className="col-1" onClick={() => navigate("/menu")}>
            <img src={back} className="icon_extra back" alt="" />
          </div>
          <div className="col-9 text-center">
            <h1>{<Skeleton width={150} />}</h1>
          </div>
          <div className="col-1">

          </div>
          <div className="col-12 text-center">
            <h3 style={{ color: THEME.primaryColor() }}>{<Skeleton width={100} />}</h3>
          </div>
        </div>

        <div className="main_product">
          <Skeleton className="img_product" height={360} />


          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton height={44} width={100} borderRadius={30} />
          </div>

          <div className="div_text_product">
            <h3 className="title_prduct" style={{ color: THEME.primaryColor() }}>Descrizione</h3>
            <p style={{ color: THEME.unselectedColor }}><Skeleton count={3} /></p>
          </div>
          <div className="div_text_product">
            <div className="flex">
              <img src={ingredienti} className="icon_extra" alt="" />
              <h3 className="title_prduct" style={{ color: THEME.primaryColor() }}>Ingredienti</h3>
            </div>
            <Skeleton width={100} />
          </div>
        </div>
      </main>
    </SkeletonTheme>
  )
}

export default SkeletonDettaglioProdotto