export const THEME = {
    backgroundColor: "#FFFFFF",
    primaryColor: (opacity) => `rgba(0, 36, 94, ${opacity || 1})`,
    transparentColor:"rgba(255, 255, 255, 0)",
    backgroundCategory: "#00245e",
    backgroundProduct: "#EED885",
    secondaryColor: "#1e56b0",
    errorColor: "#8E1B1C",
    whiteColor: "#FFFFFF",
    unselectedColor: "#5F584F",
    unselectedIcon: "#333333",
    textColor: "#252525",
    disabledTab: "#514F48"
}