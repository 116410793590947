import "./detail.css";
import { useEffect, useState } from "react";
import productsApi from "../../../services/productsApi";
import ingredienti from "../../../assets/icons/ingredienti.svg";
import InfoMeal from "../../../components/Info/InfoMeal";
import InfoDrink from "../../../components/Info/InfoDrink";
import plus from "../../../assets/icons/plus.svg";
import back from "../../../assets/icons/back.svg";
import allergeni from "../../../assets/icons/allergeni.svg";
import { useNavigate } from "react-router-dom";
import { THEME } from "../../../config";
import SkeletonDettaglioProdotto from "../../../components/skeleton/SkeletonDettaglioProdotto";
import placeholder from "../../../assets/image/logo_placeholder.png";

function DettaglioProdotto() {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [headerClassName, setHeaderClassName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf("/") + 1);
    let response = null;
    try {
      response = await productsApi.retrieveProduct(id);
    } catch (error) {
      console.error("DettaglioProdotto.getDetail", error);
    }
    if (response?.data?.data) {
      setProduct(response.data.data);
      setTimeout(() => setIsLoading(false), 1000);
    }
  };

  const handleScroll = () => {
    if (document.querySelector(".topbar")?.getBoundingClientRect()?.y < 0) {
      setHeaderClassName("is_sticky");
    } else {
      setHeaderClassName("");
    }
  };
  useEffect(() => {
    window.onscroll = () => handleScroll();
  }, [headerClassName]);

  if (isLoading) {
    return <SkeletonDettaglioProdotto />;
  }

  return (
    <main>
      <div className={`flex topbar ${headerClassName}`}>
        <div className="col-1" onClick={() => navigate("/menu")}>
          <img src={back} className="icon_extra back" alt="" />
        </div>
        <div className="col-9 text-center">
          <h1>{product.title}</h1>
        </div>
        <div className="col-1"></div>
        <div className="col-12 text-center">
          <h3 style={{ color: THEME.primaryColor() }}>{product.categoryName}</h3>
        </div>
      </div>

      <div className="main_product">
        <div className="div_img_product">
          <img crossOrigin="anonymous" src={product.image || placeholder} className="img_product" alt="" />
        </div>

        {product?.type && (product?.type === "drink" ? <InfoDrink price={product.price} categoryIcon={product.categoryIcon} alcoholPercentage={product.alcoholPercentage} quantity={product.quantity} isAvailable={product.available} /> : <InfoMeal calories={product.calories} price={product.price} categoryIcon={product.categoryIcon} isAvailable={product.available} />)}
        {product?.description && (
          <div className="div_text_product">
            <h3 className="title_prduct" style={{ color: THEME.primaryColor() }}>
              Descrizione
            </h3>
            <p style={{ color: THEME.unselectedColor }}>{product.description}</p>
          </div>
        )}
        {product?.ingredients?.length > 0 && (
          <div className="div_text_product">
            <div className="flex">
              <img src={ingredienti} className="icon_extra" alt="" />
              <h3 className="title_prduct" style={{ color: THEME.primaryColor() }}>
                Ingredienti
              </h3>
            </div>
            <ul style={{ color: THEME.unselectedColor }}>
              {product?.ingredients?.map((item, index) => (
                <li key={index}>
                  <p style={{ color: THEME.unselectedColor }}>{item}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {product?.type && product?.type !== "drink" && (
          <div className="div_text_product">
            <div className="flex">
              <img src={plus} className="icon_extra" alt="" />
              <h3 className="title_prduct" style={{ color: THEME.primaryColor() }}>
                Extra
              </h3>
            </div>
            <p style={{ color: THEME.unselectedColor }}>Ogni ingrediente extra comporterà un prezzo aggiuntivo.</p>
          </div>
        )}
        {product?.allergens && (
          <div className="div_text_product">
            <div className="flex">
              <img src={allergeni} className="icon_extra" alt="" />
              <h3 className="title_prduct" style={{ color: THEME.primaryColor() }}>
                Allergeni
              </h3>
            </div>
            <p style={{ color: THEME.unselectedColor }}>{product.allergens}</p>
          </div>
        )}
      </div>
    </main>
  );
}

export default DettaglioProdotto;
