import React from "react";
import { useContext, useEffect, useState } from "react";
import { THEME } from "../../config";
import placeholder from "../../assets/image/logo_placeholder.png";





export default function Card({
  principal,
  title,
  ingredients,
  price,
  image,
  quantity,
  alcoholPercentage,
  type,
  isAvailable,
  showDetailPage,
  onClick,
  
})

{
  return (
    <div
      className="w-100"
      style={{ cursor: showDetailPage ? "pointer" : "default" }}
      onClick={onClick}
    >
      <div
        className={`card ${principal ? 'novita': ''}`} 
      >
        <div className="card_int">
        <div className="content_img">
            <img crossOrigin="anonymous" src={image || placeholder} alt="" />
            {showDetailPage && <div className="more">+</div>}
          </div>
          <div className="content">
            <h3 className="title" style={{ color: THEME.primaryColor() }}>
              {title}
            </h3>
            {ingredients && (
              <p className="ingredients">{ingredients}</p>
            )}

            <div className="d-flex detail_bar">
            {type === "drink" && (
                <>
                  <div className="col-4">
                    {" "}
                    <span>{quantity}</span>
                  </div>
                  <div className="col-4">
                    {" "}
                    <span>{alcoholPercentage ? `Vol. ${alcoholPercentage}` : ''}</span>
                  </div>
                </>
              )}
            <div className="col-4">
                <div
                  className="new_price"
                  style={{
                    backgroundColor: isAvailable
                      ? THEME.primaryColor(.07)
                      : THEME.errorColor,
                  }}
                >
                  <p className="new_price_p" style={!isAvailable ? {color: "#8E1B1C" } : {}}>{isAvailable ? price : "N.D."}</p>
                </div>
              </div>
              
              
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}
