import axios from "axios"

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.request.use(async function (config) {
   //const lang = localStorage.getItem("lang_backroom")
    config.headers["X-API-KEY"] = process.env.REACT_APP_API_KEY
    config.headers["X-LANG"] = "it"
    return config
});

export default api
