import React, { useEffect, useState } from "react";
import ConfigContext from "./ConfigContext";
import categoriesApi from "../../services/categoriesApi";
import productsApi from "../../services/productsApi";
import stringsApi from "../../services/stringsApi";

const ConfigProvider = ({ children }) => {
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [strings, setStrings] = useState([])
    useEffect(() => {
        setupData()
    }, [])

    useEffect(() => {
        if (selectedCategory) {
            getProductsList()
        }
    }, [selectedCategory])

    const setupData = async () => {
        let response,stringsResponse = null;
        try {
            response = await categoriesApi.fetchCategories();
            stringsResponse = await stringsApi.fetchStrings();
        } catch (error) {
            console.error("fetchCategories", error);
        };
        if (response?.data?.data) {
            setCategories(response.data.data);
            setSelectedCategory(response.data.data[0]?.id)
        };
        if (stringsResponse?.data?.data) {
            setStrings(stringsResponse.data.data);
        };
    }

    const getProductsList = async () => {
        let response = null;
        try {
            response = await productsApi.fetchProducts(selectedCategory);
        } catch (error) {
            console.error("getProductsList", error);
        };
        if (response?.data?.data) {
            setProducts(response.data.data);
        };
    }

    return (
        <ConfigContext.Provider value={{ categories, selectedCategory, setSelectedCategory, products, strings }}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;