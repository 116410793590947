import React from "react";
import calorieIcon from "../../assets/icons/calorie.svg";
import Price from "../price/Price";
import { THEME } from "../../config";


export default function InfoMeal({ isAvailable, calories, categoryIcon, price }) {
    return (
        <div className="flex extra">
            {calories &&
                <div className="flex kcal">
                    <img src={calorieIcon} className="icon_extra" alt="" />
                    <p style={{ color: THEME.unselectedColor, fontWeight: 400 }}>{calories} kcal</p>
                </div>
            }
            <Price
                price={price}
                isAvailable={isAvailable}
                categoryIcon={categoryIcon}
            />
        </div>
    )
}