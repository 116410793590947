import React, { useState, useEffect } from 'react';

const ColoredSvg = ({ url, stroke, className, style }) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    const fetchSvg = async () => {
      const response = await fetch(url);
      const text = await response.text();
      setSvgContent(text);
    };

    fetchSvg();
  }, [url]);

  return svgContent ? (
    <div className={`category_icon_div ${className}`} style={style} dangerouslySetInnerHTML={{ __html: svgContent.replace(/fill=".*?"/g, `fill="${stroke}"`) }} />
  ) : null;
};

export default ColoredSvg;