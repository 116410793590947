import React from "react";
import Price from "../price/Price";
import { THEME } from "../../config";

export default function InfoDrink({
    quantity,
    alcoholPercentage,
    categoryIcon,
    price,
    isAvailable
}) {
    return (

        <div className=" flex extra_birra">
            <div className="text-left kcal">
                <h3 style={{ color: THEME.primaryColor()}}>Capienza</h3>
                <p style={{ color: THEME.unselectedColor}}>{quantity}</p>
            </div>
            <Price
                price={price}
                isAvailable={isAvailable}
                categoryIcon={categoryIcon}
            />
            <div className="text-right">
                <h3 style={{ color: THEME.primaryColor()}}>Vol.</h3>
                <p style={{ color: THEME.unselectedColor}}>{alcoholPercentage}</p>
            </div>
        </div>
    );
}
