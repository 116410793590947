import React from "react";
import { THEME } from "../../config";

export default function Divider({ title }) {
  return (
    <div className="subcategory">
         <p style={{ color: THEME.primaryColor() }}>{title}</p>
      <ul className="divider">
        <span></span>
        <span></span>
        <span></span>
      </ul>
     
    </div>
  );
}
