import api from "../api";

const endpoint = {
    fetchProducts: (id) => {
        return api.get(`/category/${id}/products`)
    },
    retrieveProduct: (id) => {
        return api.get(`/product/${id}`)
    }
}

export default endpoint;