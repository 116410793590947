import "./menu.css";
import logo from "../../assets/image/logo.svg";
import { useContext, useEffect, useState } from "react";
import ConfigContext from "../../context/config/ConfigContext";
import { useNavigate } from "react-router-dom";
import Divider from "../../components/divider/Divider";
import Card from "../../components/card/Card";
import Category from "../../components/icons/Category";
import { BallTriangle } from "react-loader-spinner";
import { THEME } from "../../config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Menu() {
	const navigate = useNavigate();
	const { categories, selectedCategory, setSelectedCategory, products, strings } = useContext(ConfigContext);
	const [headerClassName, setHeaderClassName] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingProducts, setIsLoadingProducts] = useState(true);
	const handleScroll = () => {
		if (document.querySelector(".category_container")?.getBoundingClientRect()?.y <= 0) {
			setHeaderClassName("is_sticky");
		} else {
			setHeaderClassName("");
		}
	};

	useEffect(() => {
		window.onscroll = () => handleScroll();
	}, [headerClassName]);

	useEffect(() => {
		if (categories) {
			setTimeout(() => setIsLoading(false), 1000);
		}
		if (products !== null) {
			setTimeout(() => setIsLoadingProducts(false), 500);
		}
	}, [categories, products]);

	if (isLoading) {
		return (
			<div>
				<BallTriangle
					height={70}
					width={70}
					radius={5}
					color={THEME.primaryColor()}
					ariaLabel="ball-triangle-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
			</div>
		);
	}

	return (
		<main>
			<div className="logo_container">
				<img
					src={logo}
					className="logo"
					alt="logo"
				/>
			</div>
			{/* RENDER DELLE CATEGORIE */}
			<div className={`category_container ${headerClassName}`}>
				<div className="div_category">
					{categories?.map((item, index) => {
						const isSelected = selectedCategory === item.id;
						return (
							<Category
								key={index}
								name={item.name}
								icon={item.icon}
								isSelected={isSelected}
								onClick={() => {
									setIsLoadingProducts(true);
									setSelectedCategory(item.id);
								}}
							/>
						);
					})}
				</div>
			</div>
			{/* RENDER DEI PRODOTTI */}
			{!isLoadingProducts ? (
				products?.map((item, index) => {
					return (
						<div
							className="container_content"
							key={index}>
							{item?.subcategory && <Divider title={item?.subcategory?.title} />}
							<div className="flex products">
								{item?.data?.map((product, index) => {
									return (
										<Card
											principal={selectedCategory === "5f4a2512-649a-4872-9502-6abfaf0d7d82"}
											key={index}
											title={product.title}
											image={product.image}
											price={product.price}
											isAvailable={product.available}
											ingredients={product?.ingredients}
											type={product.type}
											alcoholPercentage={product.alcoholPercentage}
											quantity={product.quantity}
											showDetailPage={product.showDetailPage}
											onClick={() => product.showDetailPage && navigate(`${product.id}`)}
										/>
									);
								})}
							</div>
						</div>
					);
				})
			) : (
				<SkeletonTheme
					baseColor={"rgb(212,212,212)"}
					highlightColor="#EAEAEA">
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Skeleton
							width={600}
							height={100}
							style={{ marginTop: 20 }}
						/>
						<Skeleton
							width={600}
							height={100}
							style={{ marginTop: 10 }}
						/>
						<Skeleton
							width={600}
							height={100}
							style={{ marginTop: 10 }}
						/>
						<Skeleton
							width={600}
							height={100}
							style={{ marginTop: 10 }}
						/>
					</div>
				</SkeletonTheme>
			)}

			<div className="div_nb">
				<p className="nb_sotto">{strings?.balconcinoHeader}</p>
				<p className="nb_sotto">{strings?.balconcino1}</p>
				<p className="nb_sotto">{strings?.balconcino2}</p>
				<p className="nb_sotto">{strings?.balconcino3}</p>
			</div>
		</main>
	);
}

export default Menu;
