import "./App.css";
import { Route, Routes } from "react-router-dom";
import Menu from "./screens/menu/Menu";
import Home from "./screens/home/Home";
import ConfigProvider from "./context/config/ConfigProvider";
import DettaglioProdotto from "./screens/menu/detail/DettaglioProdotto";
import NotFound from "./screens/error/NotFound";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useEffect } from "react";

function App() {
	useEffect(() => {
		CookieConsent.run({
			categories: {
				analytics: {
					enabled: true,
					readOnly: false,
				},
			},

			onConsent: ({ cookie }) => {
				if (cookie.categories) {
					const analyticsEnabled = cookie.categories.find((category) => {
						return category === "analytics";
					});

					if (analyticsEnabled) {
						window.dataLayer = window?.dataLayer || [];
						function gtag() {
							if (window?.dataLayer) {
								window?.dataLayer.push(arguments);
							}
						}
						gtag("js", new Date());
						gtag("config", "G-T3S8FW7P2S");
					}
				}
			},

			language: {
				default: "it",
				translations: {
					it: {
						consentModal: {
							title: "Cookie Policy",
							description: "Questo sito utilizza i cookie per semplificare e personalizzare l'esperienza di navigazione e alcuni sono essenziali per il suo corretto funzionamento.",
							acceptAllBtn: "Accetta tutti",
							cceptNecessaryBtn: "Rifiuta tutti",
							showPreferencesBtn: "Impostazioni",
						},
						preferencesModal: {
							title: "Preferenze Cookie",
							savePreferencesBtn: "Salva impostazioni",
							acceptAllBtn: "Accetta tutti",
							sections: [
								{
									title: "Google Analytics",
									description: "Cookie per la gestione delle metriche di Google Analytics",
									linkedCategory: "analytics",
								},
								{
									title: "Ulteriori informazioni",
									description: 'Per altre informazioni, leggi la <a download href="/cookie-policy.pdf">Cookie Policy</a>.',
								},
							],
						},
					},
				},
			},
		});
	});

	return (
		<ConfigProvider>
			<div className="app">
				<div className="pattern"></div>
				<Routes>
					<Route
						path="/"
						element={<Home />}
					/>
					<Route
						path="menu"
						element={<Menu />}
					/>
					<Route
						path="menu/:id"
						element={<DettaglioProdotto />}
					/>
					<Route
						path="*"
						element={<NotFound />}
					/>
				</Routes>
			</div>
		</ConfigProvider>
	);
}

export default App;
